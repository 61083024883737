<template>
    <div class="container-fluid">
        <Quote/>
        <GoBack next="Mavjud kurslar"/>

        <div class="row justify-content-center mb-0 pb-0">
            <div class="col-11">
                <div ref="top"></div>
                <table class="table table-hover border">
                    <thead>
                    <tr>
                        <th class="pl-1 pl-sm-5">
                            #
                        </th>
                        <th class="pl-1 pl-sm-5">
                            Kurs nomi
                        </th>
                        <th class="pl-1 pl-sm-5">
                            Barchasi
                        </th>
                        <th class="pl-1 pl-sm-5">
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr
                        class="pl-3 pt-3 pb-3"
                        v-for="(course, index) in getAllModules"
                        :key="course.id"
                    >
                        <td class="pl-2 pl-sm-5">
                            <span>
                                {{ index + 1 }}
                            </span>
                        </td>

                        <td class="pl-2 pl-sm-5">
                            <span
                                v-if="!course.isArchive"
                                class="btn text-primary agree-btn"
                            >
                                {{ course.name }}
                            </span>
                            <span
                                v-else
                                class="btn text-danger agree-btn"
                            >
                                {{ course.name }}
                            </span>
                        </td>
                        <td v-if="!course.isArchive">
                            <select
                                @change="archive(course.id)"
                                class="text-primary agree-btn"
                                style="width: 115px;border-radius: 5px;height: 71%;border-color: black;text-align: left"

                            >
                                <option>
                                    Aktiv
                                </option>
                                <option>
                                    <button
                                        v-if="!course.isArchive"
                                        style="width: 110px"
                                        type="button"
                                    >
                                        Arxivga olish
                                    </button>
                                </option>
                            </select>
                        </td>
                        <td v-else>
                            <select
                                @change="unArchive(course.id)"
                                class="text-danger agree-btn"
                                style="width: 115px;border-radius: 5px;height: 71%;border-color: black;text-align: left"
                            >
                                <option>
                                    Arxiv
                                </option>
                                <option>
                                    <button
                                        style="width: 110px"
                                        type="button"
                                    >
                                        Arxivdan chiqarish
                                    </button>
                                </option>
                            </select>
                        </td>

                        <td>
                            <router-link
                                :to="'/change-course/' + course.id"
                                :course="course"
                            >
                                <img
                                    class="ml-5"
                                    style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                    src="../assets/pen.png"
                                    alt=""
                                >
                            </router-link>
                            <img
                                @click="showModal(course.id)"
                                class="ms-3"
                                style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                src="../assets/trash.png"
                                alt=""
                            >
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div>
                    <b-pagination
                        v-if="getAllModulesTotal >= 20"
                        class="mt-0 mb-5"
                        v-model="currentPage"
                        pills
                        align="center"
                        :per-page="20"
                        :total-rows="getAllModulesTotal"
                        @input="fetchCoursesByPage"
                        :click-handler="fetchCoursesByPage"
                    >
                    </b-pagination>
                </div>
            </div>
        </div>

        <div>
            <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                            <span>
                                <h3>Ushbu kurs arxivga olindi</h3>
                            </span>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="hide"
                >
                    OK
                </b-button>
            </b-modal>
        </div>
        <div>
            <b-modal ref="modal-successful" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                            <span>
                                <h3>Ushbu kurs arxivdan chiqarildi</h3>
                            </span>
                </div>
                <b-button
                    class="mt-3 float-end"
                    style="width: 100px"
                    variant="btn btn-primary"
                    size="md"
                    @click="shownArchive"
                >
                    OK
                </b-button>
            </b-modal>
        </div>

        <div>
            <b-modal ref="modal-delete-success" hide-footer title="Kadirov.Dev">
                <div class="d-block text-center">
                    <h3>Ushbu kurs turini rostdan ham o'chirmoqchimisiz?</h3>
                </div>
                <b-button
                    class="mt-3 p-1" style="width: 100px"
                    variant="btn btn-primary"
                    size="lg"
                    @click="hideErrorModal"
                >
                    Yo'q
                </b-button>
                <b-button
                    class="mt-3 p-1 float-end"
                    style="width: 100px"
                    variant="btn btn-danger"
                    size="lg"
                    @click="deleteThisCourse"
                >
                    Ha
                </b-button>
            </b-modal>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import GoBack from "../components/GoBack";
import Quote from "../components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CourseStudentPage",
    components: {
        Quote,
        GoBack
    },
    data() {
        return {
            show: true,
            currentPage: 1,
            courseId: null,
            course: {
                name: '',
                isArchive: null
            }
        }
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchAllModules(this.currentPage)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions(['fetchAllModules', 'archiveTheCourse', 'deleteOneCourse']),
        archive(courseId) {
            this.archiveTheCourse({id: courseId, isArchive: true})
                .then(() => {
                    this.hide()
                    this.$refs['modal-success'].show()
                    this.fetchAllModules()
                })
        },
        unArchive(courseId) {
            this.archiveTheCourse({id: courseId, isArchive: false})
                .then(() => {
                    this.hide()
                    this.$refs['modal-successful'].show()
                    this.fetchAllModules()
                })
        },
        hide() {
            this.$refs['modal-success'].hide()
        },
        shownArchive() {
            this.$refs['modal-successful'].hide()
        },
        deleteThisCourse() {
            this.deleteOneCourse(this.courseId)
                .then(() => {
                    this.$refs['modal-delete-success'].hide()
                    this.fetchAllModules()
                })
        },
        showModal(id) {
            console.log(id)
            this.courseId = id
            this.$refs['modal-delete-success'].show()
        },
        hideErrorModal() {
            this.$refs['modal-delete-success'].hide()
        },
        fetchCoursesByPage() {
            this.$router.push('/courses/page/' + this.currentPage)
            this.$refs.top.scrollIntoView()

        },
    },
    computed: {
        ...mapGetters(['getAllModules', 'getAllModulesTotal', 'getArchiveModule'])
    },
    mounted() {
        this.show = true
        this.fetchAllModules(this.currentPage)
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Open+Sans:wght@400;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

img {
    width: 20px;
    height: 20px;
}

.agree-btn {
    font-size: 12px;
    font-family: Comfortaa, cursive;
    padding: 5px;
    cursor: pointer;
}

.row {
    margin-top: 111px;
}

table {
    margin-bottom: 48px;
}

select {
    box-shadow: 0 0 0 0.1rem black;
    padding-left: 10px;
}

input {
    padding: 5px;
}

table th {
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    padding-bottom: 1.2rem;
    background-color: #E5E5E5;
    color: rgba(0, 0, 0, 0.5);
}

tbody tr {
    height: 60px;
    vertical-align: text-top;
}

table tr select {
    margin-right: 10px;
    border: none;
}

table tbody tr td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;

    background-color: #FFFFFF;
    color: #000000;
}

</style>
